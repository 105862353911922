const BoxStyle = {
  Input:
    "block w-[300px] h-[40px] p-3 text-sm text-gray-900 border border-gray-300 rounded-l-lg bg-gray-50 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500",
  btn: "h-[40px] w-[100px] bg-[#3255B0] text-white rounded-r-lg",
  th: "text-[#000000] cursor-pointer p-2",
  td: "text-[#000000] font-medium p-2",
  noRecord: "flex mt-4 justify-center w-[100%]",
  AddBtn: "ml-2 md:ml-0 h-[40px] bg-[#3255B0] text-white py-2 px-8 rounded",
  tableDiv: "rounded-t-xl overflow-hidden pt-14  pl-0 p-10 ",
};

export default BoxStyle;
