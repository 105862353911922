import React, { useState } from 'react';
import { Button, Stack, Typography, Paper } from '@mui/material';
import { FormState } from './Form';
import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DateTimePicker } from '@mui/x-date-pickers';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Send } from '@mui/icons-material';

export default function Page(props) {
    const { data, handleSubmit, setFormState, titleIcon: TitleIcon } = props;
    const { t } = useTranslation();

    const handleBack = () => {
        console.log('Back button clicked');
        setFormState(data.type, TitleIcon);
    };

    return (
        <Stack spacing={2} sx={{ mt: 4 }}>
            <Typography variant="h3">{data.title}</Typography>
            <Typography variant="h4">
                {TitleIcon && <TitleIcon style={{ marginRight: '8px' }} />}
                {t(`formState-type.${data.type}`)}
            </Typography>
            <Paper elevation={1} style={{ padding: '10px', backgroundColor: '#f5f5f5', marginBottom: '10px' }}>
                <Typography variant="body1"><strong>{t('start')}:</strong> {dayjs(data.start).format(t('date-time-format'))}</Typography>
            </Paper>
            <Paper elevation={1} style={{ padding: '10px', backgroundColor: '#f5f5f5', marginBottom: '10px' }}>
                <Typography variant="body1"><strong>{t('end')}:</strong> {dayjs(data.end).format(t('date-time-format'))}</Typography>
            </Paper>
            <Stack spacing={2} direction="row">
                <Button variant="contained" color="secondary" onClick={handleBack} fullWidth>
                    {t('back')}
                </Button>
                <Button variant="contained" color="success" onClick={handleSubmit} fullWidth endIcon={<Send />}>
                    {t('send')}
                </Button>
            </Stack>
        </Stack>
    );
};