import { Dayjs } from "dayjs";

/**
 * Formats a given Dayjs date object into a string with the format "YYYY-MM-DD HH:mm".
 *
 * @param {Dayjs} date - The Dayjs date object to format.
 * @returns {string} The formatted date string.
 */
function FormatDateTime(date : Dayjs) : string {
    console.log("Formating date", date);
    return (
        date.year() +
        "-" +
        (date.month() + 1) +
        "-" +
        date.date() +
        " " +
        `${date.hour().toString().padStart(2, "0")}:${date
            .minute()
            .toString()
            .padStart(2, "0")}`
      );
};

/**
 * Converts a given number of seconds into a time format (HH:MM).
 *
 * @param {number} totalSeconds - The total number of seconds to convert.
 * @returns {string} The formatted time string in HH:MM format.
 */
function ConvertSecondsToTimeFormat(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}`;
}

function CalculateDuration(start, end) : string {
    const _start = new Date(start);
    const _end = new Date(end);
    console.log("Calculating duration", _start, _end);
    const durationMs = _end.getTime() - _start.getTime();

    const hours = Math.floor(durationMs / (1000 * 60 * 60));
    const minutes = Math.floor((durationMs % (1000 * 60 * 60)) / (1000 * 60));

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
}

function FormatDate(dateString : string) : string {
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString('de-de', { year: 'numeric', month: 'short', day: 'numeric' }).replace(',', '');
    return formattedDate;
}

export { FormatDateTime, FormatDate, ConvertSecondsToTimeFormat, CalculateDuration };