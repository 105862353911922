import React from 'react';
import { FormState } from './Form';
import { Grid, Button } from '@mui/material';
import WorkIcon from '@mui/icons-material/WorkOutline';
import BreakIcon from '@mui/icons-material/FreeBreakfast';
import StandbyIcon from '@mui/icons-material/Alarm';
import OutIcon from '@mui/icons-material/ExitToApp';
import { useTranslation } from 'react-i18next';

const CategoryButton = ({ icon: Icon, label, onClick }) => (
    <Grid item xs={12} sm={6} lg={3}>
        <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            startIcon={<Icon/>}
            style={{ padding: '10px', textTransform: 'none' }}
            onClick={onClick}
        >
            {label}
        </Button>
    </Grid>
);

const PageSelection = (props) => {
    const { t } = useTranslation();
    const { data, handleChange, setFormState } = props;
    const handleButtonClick = (value, Icon) => {
        setFormState(value, Icon);
    };

    return (
        <Grid container spacing={1}>
            <CategoryButton
                icon={WorkIcon}
                label={t('formState-type.work')}
                onClick={() => handleButtonClick(FormState.WORK, WorkIcon)}
            />
            <CategoryButton
                icon={BreakIcon}
                label={t('formState-type.break')}
                onClick={() => handleButtonClick(FormState.BREAK, BreakIcon)}
            />
            <CategoryButton
                icon={StandbyIcon}
                label={t('formState-type.standby')}
                onClick={() => handleButtonClick(FormState.STANDBY, StandbyIcon)}
            />
            <CategoryButton
                icon={OutIcon}
                label={t('formState-type.out')}
                onClick={() => handleButtonClick(FormState.OUT, OutIcon)}
            />
        </Grid>
    );
};

export default PageSelection;