import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Page_Selection from './Page_Selection';
import Page_TimeEntry from './Page_TimeEntry';
import Page_Submit from './Page_Submit';
import { PostTimeData } from '../../Api/POST/Times';
import { FormatDateTime } from '../../utils/TimeUtils.tsx';

export const FormState = {
    SELECTION: 'selection',
    WORK: 'work',
    BREAK: 'break',
    STANDBY: 'standby',
    OUT: 'out',
    SUBMIT: 'submit',
};

const AddTimeEntryForm = ({ onFormSubmit }) => {
    const { t } = useTranslation(); // Initialize translation hook

    const [formState, setFormState] = useState(FormState.SELECTION);

    const [formData, setFormData] = useState({
        type: '',
        title: '',
        icon: '',
        start: '',
        end: '',
    });

    const handleChange = (event) => {
        console.log('Component Change:', event);
        setFormData({
            ...formData,
            [event.target.name]: event.target.value
        });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // Handle form submission logic here
        var data = prepareData(formData);
        console.log('Form submitting', data);
        var response = PostTimeData(data);
        response
            .then((value) => {
                updateFormState(FormState.SELECTION);
                if (onFormSubmit) {
                    onFormSubmit();
                }
            })
            .catch((error) => {
                console.log('Error:', error);
            });
    };

    const resetData = () => {
        console.log('Resetting data');
        setFormData({
            type: '',
            title: '',
            icon: '',
            start: '',
            end: '',
        });
    }

    const prepareData = (data) => {
        console.log('Preparing data:', data);
        var result = {
            [data.type]: [{
                start: FormatDateTime(data.start),
                end: FormatDateTime(data.end)
            }]
        };

        console.log(result);
        return result;
    };

    const updateFormState = (newState, Icon) => {
        setFormState(newState);
        if (newState === FormState.SELECTION) {
            resetData();
        } else if (newState != FormState.SUBMIT) {
            console.log('Updating form state:', t(`formState-title.work`));
            setFormData({
                ...formData,
                type: newState,
                title: t(`formState-title.${newState}`),
                icon: Icon
            });
        } else {
            setFormData({
                ...formData,
                title: t(`formState-title.${newState}`)
            });
        }
    };

    /* PAGE SELECTION */
    var page;
    console.log('Switching to:', formState);
    switch (formState) {
        default:
        case FormState.SELECTION:
            page = (
                <Page_Selection
                    data={formData}
                    setFormState={updateFormState}
                    handleChange={handleChange}
                />
            );
            break;
        case FormState.WORK:
        case FormState.STANDBY:
        case FormState.BREAK:
            page = (
                <Page_TimeEntry
                    data={formData}
                    setFormState={updateFormState}
                    handleChange={handleChange}
                    TitleIcon={formData.icon}
                />
            );
            break;
        case FormState.OUT:
            page = (
                <Page_Selection
                    data={formData}
                    setFormState={updateFormState}
                    handleChange={handleChange}
                />
            );
            break;
        case FormState.SUBMIT:
            page = (
                <Page_Submit
                    data={formData}
                    setFormState={updateFormState}
                    handleSubmit={handleSubmit}
                    titleIcon={formData.icon}
                />
            );
    }

    return <div style={{ margin: "14px" }}>{page}</div>;
};

export default AddTimeEntryForm;