import * as React from "react";
import UserTable from "./UserModal/UserTable";
import Style from "../Style/UserDetail";
import { ToggleTheme } from "../../../context/ToggleThemeProvider";
import { FetchTimes } from "../../../Api/POST/Times";
import ErrorBoundary from "../../../utils/ErrorBoundary";
import TimeEntryForm from "../../../Components/AddTimeEntryForm/Form";
import MonthSelectorComponent from "../../../Components/MonthSelectorComponent.jsx";
import { ConvertSecondsToTimeFormat } from "../../../utils/TimeUtils.tsx";

export default function UserDetail(props) {
  const {
    currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate, setCurrentDate, minDate, setMinDate, detailModalOpen, setDetailModalOpen
  } = props;

  const [userTimes, setUserTimes] = React.useState();
  const [filteredItems, setFilteredItems] = React.useState([]);
  const [downloadItems, setDownloadItems] = React.useState([]);
  const [detailTime, setDetailTime] = React.useState([]);
  const [successModal, setSuccessModal] = React.useState(false);
  const jwtToken = localStorage.getItem("jwt");
 
  const MonthSelectorProps = {
    currentMonth, setCurrentMonth, currentYear, setCurrentYear, open,
    setOpen, months, setCurrentDate, minDate
  };

  const userTableProps = {
    filteredItems, setFilteredItems, downloadItems, setDownloadItems, currentMonth, currentYear, months, detailTime, setDetailTime, detailModalOpen, setDetailModalOpen
  };

  React.useEffect(() => {
    fetchUserTimes();
  }, [successModal, jwtToken, currentYear, currentMonth]);

  const fetchUserTimes = () => {
    const params = `/api/me/times?from=${currentYear}-${currentMonth + 1}-01&to=${currentYear}-${currentMonth + 2}-01&mode=sum`;
    FetchTimes(params)
      .then(data => data && setUserTimes(data))
      .catch(console.error);

    const params2 = `/api/me/times?from=${currentYear}-${currentMonth + 1}-01&to=${currentYear}-${currentMonth + 2}-01&mode=perday`;
    FetchTimes(params2)
      .then(data => data && setDetailTime(data))
      .catch(console.error);
  };

  const handleFormSubmit = () => {
    fetchUserTimes();
  };

  React.useEffect(() => {
    if (userTimes) {
      const items = Object.keys(userTimes).map(key => ({
        id: key,
        date: key,
        time: ConvertSecondsToTimeFormat(userTimes[key].total),
      }));
      setFilteredItems(items);
      setDownloadItems(items.map(({ id, date, time }) => ({ Datum: date, Dauer: time })));
    }
  }, [userTimes]);

  
  const { ThemeToggle } = ToggleTheme();
  
  return (
    <>
      <div className={ThemeToggle === "dark" ? "bg-browndark" : ""}>
        <ErrorBoundary>
          <TimeEntryForm onFormSubmit={handleFormSubmit} />
        </ErrorBoundary>
      </div>
      <div className={Style.div5}>
        <div className="w-[100%]">
          <ErrorBoundary>
          <div className={"bg-dullBlack justify-center flex w-[100%] whitespace-nowrap"}>
          <MonthSelectorComponent
            {...MonthSelectorProps}
          />
        </div>
            <UserTable {...userTableProps} />
          </ErrorBoundary>
        </div>
      </div>
    </>
  );
}
