import EmployeeTimesPage from "../../Components/EmployeeTimesPage.jsx";
import { useTranslation } from "react-i18next";
import BoxStyle from "./Style/Style.jsx";
import { useEffect, useState } from "react";
import Confirmation from "../../Pages/UserDetail/Component/UserModal/Confirmation.jsx"
import { FetchTimes } from "../../Api/TimeConfirmation.js";
import { ConvertSecondsToTimeFormat } from "../../utils/TimeUtils.tsx";

function TimeApproval(props) {
    const { t } = useTranslation();
    const [userTimes, setUserTimes] = useState();
    const [editable, setEditable] = useState(false);
    const [filteredItem, setFilteredItems] = useState([]);
    const [detailTime, setDetailTime] = useState([]);
    const [disableFields, setDisableFields] = useState(false);
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const { setCurrentComponent, currentMonth, currentYear } = props

    var employeeId = localStorage.getItem("awo-employeeId") ?? 0;

    useEffect(() => {
        setCurrentComponent(2);
    }, [])


    useEffect(() => {
        //Parameters to send in get request for whole month
        var params = `/api/employees/${employeeId}/times?from=${currentYear}-${currentMonth + 1
            }-01&to=${currentYear}-${currentMonth + 2}-01&mode=sum`;

        var userRecord = FetchTimes(params);
        userRecord
            .then((data) => {
                if (data) {
                    setFilteredItems(
                        Object.keys(data)?.map((key) => {
                            const entry = data[key];
                            return {
                                id: key,
                                date: key,
                                time: ConvertSecondsToTimeFormat(entry.total),
                            };
                        })
                    );
                }
                else {
                    setFilteredItems([]);
                }
            })
            .catch((error) => {
            });


        //Parameters to send in get request for whole day
        var params2 = `/api/employees/${employeeId}/times?from=${currentYear}-${currentMonth + 1
            }-01&to=${currentYear}-${currentMonth + 2}-01&mode=perday`;

        var userRecord1 = FetchTimes(params2);
        userRecord1
            .then((data) => {
                if (data) {
                    setDetailTime(data);
                }
            })
            .catch((error) => {
            });
    }, [currentYear, currentMonth]);


    useEffect(() => {
        if (disableFields) {
            setDisableFields(false);
            //Add api to post data here
        }
    }, [disableFields])

    return (
        <>
            <div className={"bg-dullbrown h-[60px] " + (editable && "flex justify-beween")}>
                {editable &&
                    <button
                        className={BoxStyle.cancelBtn}
                        onClick={() => { setEditable(false) }}
                    >
                        {t("Cancel")}
                    </button>}

                <div className="bg-dullbrown flex flex-end">
                    <button
                        className={BoxStyle.addBtn}
                        onClick={() => {
                            if (!editable) {
                                setEditable(true)
                            }
                            else {
                                setConfirmationModalOpen(true);
                            }
                        }}
                    >
                        {!editable ? t("start-editing") : t("Save")}
                    </button>
                </div>
            </div>

            <EmployeeTimesPage
                editable={editable} setEditable={setEditable} setUserTimes={setUserTimes} detailTime={detailTime} userTimes={userTimes} filteredItem={filteredItem}
            />

            <Confirmation
                disableFields={disableFields}
                setDisableFields={setDisableFields}
                confirmationModalOpen={confirmationModalOpen}
                setConfirmationModalOpen={setConfirmationModalOpen}
            />
        </>
    );
}

export default TimeApproval;
