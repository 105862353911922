import React from "react";
import BoxStyle from "../Style/PersonStyle";
import { MenuItem, Menu, IconButton } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { ToggleTheme } from "../../../context/ToggleThemeProvider";
import { Uparrow, Downarrow } from "../../../Images/CommonLogos";
import MenuItems from "../../../utils/MenuItems"
import { useTranslation } from "react-i18next";

function PersonTable({
  setFiltername,
  filtername,
  setFilteremail,
  filteremail,
  filteredItems,
  openmenu,
  handleClick,
  anchorEl,
  handleClosemenu,
}) {

  var { ThemeToggle } = ToggleTheme();
  const { t } = useTranslation();

  return (
    <div className={BoxStyle.tableDiv + "mt-[10px] " + (ThemeToggle === "dark" ? "md:mt-[26px]" : "md:mt-[8px]")}>
      <table className="table-auto w-full ">
        <thead style={{ textAlign: "left", paddingLeft: "10px" }}>
          <tr
            className={"pl-[10px] " + (ThemeToggle === "dark" ? "bg-gray" : "")}
          >
            <th
              className="px-8 py-2 text-black cursor-pointer"
              style={{ paddingLeft: "33px" }}
              onClick={() => {
                setFiltername(!filtername);
                setFilteremail(false);
              }}
            >
              <span className="flex">
                <span>{t("first-name")}</span>
                <span className="ml-[2px] mt-[2px]">
                  {!filtername && <Uparrow />}
                  {filtername && <Downarrow />}
                </span>
              </span>
            </th>
            <th
              className="px-8 py-2 text-black cursor-pointer"
              style={{ paddingLeft: "33px" }} >
              <span className="flex">
                <span>{t("last-name")}</span>
              </span>
            </th>
            <th
              className="px-8 py-2 text-black cursor-pointer"
              style={{ paddingLeft: "33px" }} >
              <span className="flex">
                <span>{t("user-name")}</span>
              </span>
            </th>
            <th
              className={BoxStyle.th}
            >
              <span className="flex">
                <span>{t("email")}</span>
              </span>
            </th>
            <th style={{ paddingLeft: "8px" }} className={BoxStyle.th}>
              {t("role")}
            </th>
            <th style={{ paddingLeft: "8px" }} className={BoxStyle.th}>
              {t("action")}
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredItems.map((item, index) => (
            <tr
              key={item.id}
              className={
                index % 2 === 0
                  ? "bg-narrow"
                  : ThemeToggle === "dark"
                    ? "bg-gray"
                    : ""
              }
            >
              <td className={BoxStyle.td}>{item.vorname}</td>
              <td className={BoxStyle.td}>{item.nachname}</td>
              <td className={BoxStyle.td}>{item.username}</td>
              <td className={BoxStyle.td}>{item.email}</td>
              <td className={BoxStyle.td}>{item.role}</td>
              <td className={BoxStyle.td}>
                <div >
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    aria-controls={openmenu ? "long-menu" : undefined}
                    aria-expanded={openmenu ? "true" : undefined}
                    aria-haspopup="true"
                    sx={{ color: "black" }}
                    onClick={(e) => {
                      handleClick(e, item);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    elevation={0}
                    item={item}
                    id="long-menu"
                    MenuListProps={{
                      "aria-labelledby": "long-button",
                    }}
                    anchorEl={anchorEl}
                    open={openmenu}
                    onClose={handleClosemenu}
                  >
                    <MenuItem
                      key={t(MenuItems.VERTRAG)}
                      onClick={() => {
                        handleClosemenu(t(MenuItems.VERTRAG));
                      }}
                    >
                      {t(MenuItems.VERTRAG)}
                    </MenuItem>
                    <MenuItem
                      key={t(MenuItems.Gruppen)}
                      onClick={() => {
                        handleClosemenu(t(MenuItems.Gruppen));
                      }}
                    >
                      {t(MenuItems.Gruppen)}
                    </MenuItem>
                    <MenuItem
                      key={t(MenuItems.DELETE)}
                      onClick={() => {
                        handleClosemenu(t(MenuItems.DELETE));
                      }}
                    >
                      {t(MenuItems.DELETE)}
                    </MenuItem>
                    <MenuItem
                      key={t(MenuItems.Zeiten)}
                      onClick={() => {
                        handleClosemenu(t(MenuItems.Zeiten));
                      }}
                    >
                      {t(MenuItems.Zeiten)}
                    </MenuItem>
                    <MenuItem
                      key={t(MenuItems.Bearbeiten)}
                      onClick={() => {
                        handleClosemenu(t(MenuItems.Bearbeiten));
                      }}
                    >
                      {t(MenuItems.Bearbeiten)}
                    </MenuItem>
                  </Menu>
                </div>
              </td>

            </tr>
          ))}
        </tbody>
      </table>
      {!filteredItems.length && (
        <div className={BoxStyle.noRecord}>
          <p
            className={
              "text-2xl " +
              (ThemeToggle !== "dark" ? "text-dullBlack" : "text-black")
            }
          >
            {t("no-recordFound")}
          </p>
        </div>
      )}
    </div>
  );
}

export default PersonTable;
