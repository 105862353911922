import * as React from "react";
import { Header } from './Header';
import Setting from "../../Setting/Setting";
import UserViewComponent from "../../Person/Component/UserView";
import UserDetail2 from '../../UserDetails2/UserDetail2';
import MyTimesPage from "../../UserDetail/Component/MyTimesPage";
import TimeApproval from "../../TimeApproval/TimeApproval";
import PropTypes from "prop-types";
import MenuItems from '../../../utils/MenuItems';
import ContractComponent from "../../Contract/Component/Contract";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useUser } from "../../../context/UserProvider";
import { UserLogo } from "../../../Images/DashboardImages";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { useTheme } from "../../../context/UseThemeProvider";
import { ToggleTheme } from "../../../context/ToggleThemeProvider";
import { SidebarDrawer } from './SidebarDrawer';
import { FetchTimes } from "../../../Api/POST/Times";
import { Box, Container, CssBaseline, GlobalStyles, ThemeProvider, Toolbar } from "@mui/material";

const drawerWidth = 270;

function HRDashboard() {
  const window = null;
  const { user } = useUser();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);
  const [currentDate, setCurrentDate] = React.useState(new Date());
  const [currentMonth, setCurrentMonth] = React.useState(new Date().getMonth());
  const [check, setCheck] = React.useState(true);
  const [userVertragName, setUserVertragName] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [detailModalOpen, setDetailModalOpen] = React.useState(false);
  const container = window !== undefined ? () => document.body : undefined;
  const [minDate, setMinDate] = React.useState(new Date("2024-01-01 00:00"));
  var months = ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"];
  const [currentYear, setCurrentYear] = React.useState(
    new Date().getFullYear()
  );

  var [currentComponent, setCurrentComponent] = React.useState(
    localStorage.getItem("CurrentComponent") || 0
  );

  const handleDrawerClose = () => {
    setIsClosing(true);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  var ListIcons = [
    <UserLogo />,
    <AccessTimeIcon sx={{ color: "white" }} />,
    <CalendarMonthIcon sx={{ color: "white" }} />,
    <AccessTimeIcon sx={{ color: "white" }} />,
  ];

  var ListMenu = [MenuItems.PERSONEN, MenuItems.DETAIL1, MenuItems.DETAIL2, MenuItems.VERTRAG];

  if (user.role.toLocaleLowerCase() === MenuItems.USER.toLocaleLowerCase() && check) {
    setCurrentComponent(3);
    setCheck(false);
  }
  localStorage.setItem("CurrentComponent", String(currentComponent));

  React.useEffect(() => {
    if (user.role.toLocaleLowerCase() === MenuItems.USER.toLocaleLowerCase() && check) {
      setCurrentComponent(3);
      setCheck(false);
    }
    localStorage.setItem("CurrentComponent", String(currentComponent));
  }, [currentComponent]);

  const UserDetailProps = { currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate, setCurrentDate, minDate, setMinDate, detailModalOpen, setDetailModalOpen }
  const UserDetail2Props = { currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate, setCurrentDate, minDate, setMinDate }
  const HeaderProps = { drawerWidth, detailModalOpen, setDetailModalOpen, handleDrawerToggle, userVertragName, currentComponent, setCurrentComponent, currentMonth, setCurrentMonth, currentYear, setCurrentYear, open, setOpen, months, currentDate, setCurrentDate, minDate, setMinDate }
  const TimeApprovalProps = { setCurrentComponent, currentMonth, setCurrentMonth, currentYear, setCurrentYear, months, currentDate, setCurrentDate, minDate, setMinDate }

  const SidebarDrawerProps = { drawerWidth, container, mobileOpen, handleDrawerTransitionEnd, handleDrawerClose, setCurrentComponent, setIsClosing, setMobileOpen, ListIcons, ListMenu }
  const UserViewProps = { currentComponent, setCurrentComponent, setUserVertragName }


  var setComponent = (
    <>
      {parseInt(currentComponent + "") === 0 && <UserViewComponent {...UserViewProps} />}
      {parseInt(currentComponent + "") === 1 && <ContractComponent />}
      {parseInt(currentComponent + "") === 2 && <TimeApproval {...TimeApprovalProps} />}
      {parseInt(currentComponent + "") === 3 && (
        <MyTimesPage {...UserDetailProps} />
      )}
      {parseInt(currentComponent + "") === 4 && (
        <UserDetail2 {...UserDetail2Props} />
      )}
      {parseInt(currentComponent + "") === 5 && (
        <Setting />
      )}
    </>
  );


  React.useEffect(() => {
    if (user.role === "user") {
      var params = `/api/me/min-date`;
      var userRecord = FetchTimes(params);
      userRecord
        .then((data) => {
          if (data["min-date"].length !== 0) {
            setMinDate(new Date(data[0]));
          }
        })
        .catch((error) => {
        });
    }
  }, []);

  var { ThemeToggle } = ToggleTheme();
  var { currentTheme: theme } = useTheme();

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        sx={{
          backgroundColor: ThemeToggle === "dark" ? "dark.main" : "",
          paddingInline: "0px",
        }}
      >
        <Box sx={{ display: "flex", width: "100%" }}>
          <CssBaseline />
          <Header {...HeaderProps} />
          <SidebarDrawer {...SidebarDrawerProps} />
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <GlobalStyles
              styles={{
                body: { backgroundColor: ThemeToggle === "dark" ? theme.palette.secondary.main : "" },
              }}
            />
            {setComponent}
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

HRDashboard.propTypes = {
  window: PropTypes.func,
};

export default HRDashboard;