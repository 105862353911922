import React, { useState } from 'react';
import { Box, InputLabel, Typography, Select, MenuItem } from "@mui/material";
import { ToggleTheme } from "../../context/ToggleThemeProvider";
import { Tick } from '../../Images/CommonLogos';
import { useTheme } from '../../context/UseThemeProvider';
import { useTranslation } from 'react-i18next';
import i18n from "../../utils/translation/i18n.js";

const Setting = () => {
    const { t } = useTranslation();
    var { currentTheme } = useTheme();
    var { ThemeToggle, setThemeToggle } = ToggleTheme();
    const [theme, setTheme] = useState(ThemeToggle);
    const [language, setLanguage] = useState(i18n.resolvedLanguage);

    const handleLanguageChange = (event) => {
        setLanguage(event.target.value);
    };

    const handleThemeChange = (event) => {
        setTheme(event.target.value);
    };

    const onSubmitData = (event) => {
        i18n.changeLanguage(language);
        setThemeToggle(theme)
        localStorage.setItem("ThemeColor", theme)
    }

    const onCancel = (event) => {
        setTheme(ThemeToggle);
        setLanguage(i18n.resolvedLanguage);
    }

    return (
        <>
            <Box sx={{ display: "flex", mt: "10px", color: "black" }}>
                <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                        color: (ThemeToggle === "dark" ? "white" : "black"),
                        mt: "8px",
                    }}
                >
                    Theme
                </InputLabel>
                <Select
                    value={theme}
                    onChange={handleThemeChange}
                    sx={{
                        height: "40px",
                        width: "200px",
                        ml: "120px",
                        boxShadow: 1,
                    }}
                >
                    <MenuItem value={"light"}>Light</MenuItem>
                    <MenuItem value={"dark"}>Dark</MenuItem>
                </Select>
            </Box>
            <Box sx={{ display: "flex", mt: "10px", color: "black" }}>
                <InputLabel
                    id="demo-simple-select-label"
                    sx={{
                        color: (ThemeToggle === "dark" ? "white" : "black"),
                        mt: "8px",
                    }}
                >
                    Language
                </InputLabel>
                <Select
                    value={language}
                    onChange={handleLanguageChange}
                    sx={{
                        height: "40px",
                        width: "200px",
                        ml: "100px",
                        boxShadow: 1,
                    }}
                >
                    <MenuItem value={"gb"}>English</MenuItem>
                    <MenuItem value={"de"}>Deutsch</MenuItem>
                </Select>
            </Box>
            <Box sx={{ display: "flex", mb: "100px", mt: "20px" }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    width: '140px',
                    height: '40px',
                    backgroundColor: currentTheme.palette.dullGray.main,
                    padding: '8px 32px',
                    borderRadius: '12px',
                    '&:hover': {
                        cursor: 'pointer',
                    },
                }}
                    onClick={onCancel}
                >
                    <Typography
                        className={
                            "flex font-bold " +
                            (ThemeToggle === "dark" ? "text-browndark" : "")
                        }>
                        {t("cancel")}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        width: '140px',
                        height: '40px',
                        paddingY: 1,
                        paddingX: 8,
                        marginLeft: 2,
                        borderRadius: '12px',
                        backgroundColor: currentTheme.palette.green.main,
                        '&:hover': {
                            cursor: 'pointer',
                        },
                    }}
                    onClick={onSubmitData}
                >
                    <Typography className="flex font-bold text-white">{t("Save")}</Typography>
                    <Box component="span" className="ml-2 ">
                        <Tick />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default Setting;
