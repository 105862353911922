const Style = {
  mainDiv: "flex flex-wrap ",
  btn1: "hidden md:block bg-textBox ml-[10px] mt-[36px] flex justify-center w-[80px] h-[50px] text-browndark rounded-lg",
  div2: "w-[38%] md:w-[200px]",
  div3: "md:hidden block ml-2 mt-[39px]",
  div4: "w-[100%] ",
  div5: "flex flex-wrap w-[100%]",
  div6: "hidden pl-4 lg:block w-[35%] ml-4",
};

export default Style;
